.root-layout {
	/* margin-top: 56px; */
	/* min-height: calc(100% - 60px); */
	/* margin-top: 64px; */
	padding-top: 60px;
	display: flex;
	max-height: 100vh;
	width: 100%;
	max-width: 100%;
	position: absolute;
}

.list-layout {
	width: calc(100% - 75px);
	/* margin-left: 75px; */
	width: 100%;
	background-color: #F0F2F5;
}

.common-list {
	width: 350px;
	min-width: 350px;
	background-color: #fff;
	-webkit-transition: width 0.5s ease-in-out;
	-moz-transition: width 0.5s ease-in-out;
	-o-transition: width 0.5s ease-in-out;
	transition: width 0.5s ease-in-out;
}

.chat-layout {
	min-width: 70%;
}

.filters .MuiChip-root {
	height: 25px;
	background-color: #e0e0e0 !important;
	font-size: 12px;
}

.filters .MuiChip-root .MuiChip-label {
	padding: 0 12px !important;
}

.filters .MuiChip-deleteIcon {
	height: 18px;
}

/* START - material-table styles */
.MuiTable-root {
	/* border-collapse: separate !important; */
	border-spacing: 0 2px !important;
}

.material-table {
	overflow-x: auto;
	/* width: 100%; */
	background: white;
	padding: 10px 10px 0px 10px;
	margin-top: 15px;
	border-radius: 5px;
}

.material-table table th {
	padding: 5px 10px !important;
	/* border-color: #FFC407; */
	/* border-color: #838383;
	border-style: solid;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0; */
	border-right-width: 0;
	/* background-color: #FFEAA7; */
	/* background-color: #7c139a21; */
	/* background-color: #e1d7e9; */
	color: #000000;
	font-weight: 600;
	line-height: 1.2rem;
	font-size: 12px;
	height: 40px;
	background: #EBEBEB;
}

.material-table table th:first-child {
	border-left-width: 1px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.material-table table th:last-child {
	border-right-width: 1.2px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.material-table table td {
	padding: 3px 10px;
	font-size: 12px !important;
	height: 40px;
	/* border-color: #838383;
	border-style: solid;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 0; */
	/* background-color: #FFF; */
}

.material-table table td:first-child {
	border-left-width: 1px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.material-table table td:first-child h6 {
	font-size: 12px;
	text-align: center;
}

.material-table table td:last-child {
	border-right-width: 1.2px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.material-table table tr[mode='update'] td {
	border: 0;
	padding: 10px 10px;
}

.material-table table tr[mode='add'] {
	background-color: transparent;
}

.material-table table tr[mode='add'] td {
	border: 0;
	padding: 10px 10px;
	background-color: #FFF;
}

.material-table table tr[mode='add'] td:last-child {
	background-color: transparent;
}

.material-table table tr[mode='add'] td:nth-last-child(2) {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.material-table .MuiToolbar-root {
	min-height: auto;
	height: 40px;
	margin-bottom: 14px;
}

.material-table .MuiButton-root {
	/* padding-right: 0; */
	height: 32px;
	padding: 0 10px;
	width: auto;
}

.material-table .MuiToolbar-root .MuiIconButton-colorInherit {
	margin-left: 30px;
	padding: 0px;
}

.material-table .MuiAvatar-root {
	width: 25px;
	height: 25px;
	font-size: 12px;
}

.material-table .MuiTableFooter-root td {
	border: 0;
}

.material-table .MuiTableContainer-root {
	overflow: visible;
}

.material-table td>.MuiChip-root {
	border-radius: 4px;
	height: 28px;
	/* width: 66px; */
	width: auto !important;
	background-color: transparent;
	/* color: #000000de; */
}

.material-table td>.MuiChip-root .MuiChip-label {
	padding: 0;
	font-weight: 600;
}

.material-table .MuiTableSortLabel-root {
	position: relative;
	display: block;
	padding-right: 20px;
}

.material-table .MuiTableSortLabel-root .MuiTableSortLabel-icon {
	position: absolute;
	right: 0;
	top: 3px;
}

/* END - material-table styles */

.material-table .MuiPaper-elevation2 {
	box-shadow: none;
	/* padding: 2rem; */
	background: transparent;
	border-radius: 8px;
	/* border: 1px solid #BCBCBD; */
}

/* @media (min-width: 600px) {
    .root-layout {
        margin-top: 64px;
    }
} */

/* @media (max-width: 600px) {
    .common-list {
        width: 0px;
    }
} */

.material-table table tr {
	background-color: #FFF;
}

.material-table table thead tr {
	background: transparent;
}

.material-table table tbody tr {
	border-bottom: 1px solid #0000000f !important;
}

.material-table .MuiTableRow-footer {
	background: transparent;
}

.material-table .MuiTableRow-footer td {
	height: auto;
}

.material-table .MuiTableCell-root {
	border-bottom: none;
}

.material-table .MuiTableCell-root>div>button.MuiIconButton-root {
	padding: 13px;
}

/* 
.material-table .MuiTableCell-root .MuiInputLabel-shrink + .MuiInputBase-formControl input {
	padding: 7px 14px 7px;
}


.material-table .MuiTableCell-root .MuiOutlinedInput-multiline {
	padding: 0px;
}

.material-table .MuiTableCell-root .MuiOutlinedInput-multiline textarea {
	padding: 7px 14px 7px;
} */

.common-wrapper {
	margin: 16px 25px 20px;
	/* padding: 0 20px;
	margin-top: 1rem; */
}

/* Custom perfomance table template */
.custom-table {
	/* min-height: 300px; */
	/* max-width: 500px; */

	/* color: #FFF; */
	padding: 25px;
	font-weight: 600;
}

.custom-table table {
	border-spacing: 0 15px;
	border-collapse: separate;
	margin-top: 10px;
}

.custom-table tbody td {
	/* background-color: #FFF; */
}

.custom-table tbody td,
.custom-table thead th {
	text-align: left;
	padding: 8px 15px;
}

.custom-table table td:first-child {
	/* border-left-width: 1px; */
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	/* border-width: 0px 0px 1px 1px !important; */
}

.custom-table table td:last-child {
	/* border-right-width: 1.2px; */
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	/* border-width: 0px 1px 1px 0px !important; */
}

.scrollbar-style::-webkit-scrollbar {
	border: 2px solid white;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .3);
}

.scrollbar-style ::-webkit-scrollbar {
	-webkit-appearance: 'none';
}

/* ----------table component changes ---- */
.MuiInput-underline {
	border: 1px solid #c4c4c4;
	border-radius: 5px !important;
	padding: 0px 5px;
	width: 200px;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
	border: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 0;
}

.MuiTable-stickyHeader {
	border-collapse: collapse;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
	min-height: 296px !important;
	max-height: 296px !important;
	;
	position: absolute;
	top: 0px;
}

.MuiFormControl-root label {
	font-size: 14px;
	top: -6px;
}

.MuiFormControl-root label.MuiInputLabel-shrink {
	top: 0;
}

.MuiInputLabel-shrink+.MuiInputBase-formControl input {
	padding: 15px 14px 12px;
}

.MuiInputBase-formControl input {
	padding-top: 7px;
	font-size: 14px;
}